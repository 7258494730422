import React, { useState, useEffect } from "react";
import "./App.css"; // Import the new CSS file
import { Api } from "./networking/Api";
import StockDataDisplay from "./components/StockDataDisplay";
import { StockData, MarketData } from "./components/StockDataDisplay";
import Spinner from "./components/Spinner";
import HistoricalModal from "./components/HistoricalModal";
import Login from "./components/Login";
import MarketInfo from "./components/MarketInfo";

function App() {
  const [symbol, setSymbol] = useState("");
  const [data, setData] = useState([]);
  const [scanData, setScanData] = useState<StockData[]>([]);
  const [scanMaket, setScanMarket] = useState<MarketData | undefined>(
    undefined
  );
  const [filteredScanData, setFilteredScanData] = useState<StockData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [filterValue, setFilterValue] = useState("active");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFilteredScanData(scanData);
    handleActiveOpportunities();
  };

  const fetchOportunitiesForSymbol = async (symbol: string) => {
    setIsLoading(true);
    openModal();
    const result = await Api.fetchOportunitiesForSymbol(symbol);
    if (result && result.data && result.data.length > 0) {
      setData(result.data[0]);
    } else {
      setData([]);
    }
    setIsLoading(false);
  };

  const scanOportunities = async () => {
    setIsScanning(true);
    const result = await Api.scanOportunities();
    if (result && result.data && result.data.length > 0) {
      // console.log("RESULTS", JSON.stringify(result.market, null, 2));
      setScanData(result.data);
      setScanMarket(result.market);
      setFilteredScanData(result.data);
    } else {
      setScanData([]);
    }
    setIsScanning(false);
  };

  const handleSubmitSymbol = (event: any) => {
    event.preventDefault();
    if (symbol) {
      fetchOportunitiesForSymbol(symbol);
    }
  };

  const handleItemClick = (selectedItem: StockData) => {
    setSymbol(selectedItem.symbol);
    fetchOportunitiesForSymbol(selectedItem.symbol);
  };

  const handleCheckboxClick = async (
    selectedItem: StockData,
    checked: boolean
  ) => {
    await Api.select(selectedItem.symbol, checked);
    setScanData((prevScanData) =>
      prevScanData.map((item) =>
        item.symbol === selectedItem.symbol
          ? { ...item, isSelected: checked }
          : item
      )
    );
    setFilteredScanData((prevScanData) =>
      prevScanData.map((item) =>
        item.symbol === selectedItem.symbol
          ? { ...item, isSelected: checked }
          : item
      )
    );
  };

  const checkLoginState = () => {
    const sessionToken = localStorage.getItem("accessToken");
    if (sessionToken) {
      Api.token = sessionToken;
      setIsLoggedIn(true);
      scanOportunities();
    } else {
      setIsLoggedIn(false);
    }
  };

  const handleActiveOpportunitiesFilter = (event: any) => {
    const value = event.target.value;
    setFilterValue(value);
  };

  const isSentimentPositive = (stock: StockData): boolean => {
    return stock.sentiment.sentimentscore > 5;
  };

  const isTrendingUp = (stock: StockData): boolean => {
    return stock.regression_slope > 0;
  };

  const isCheap = (stock: StockData): boolean => {
    return stock.regression_score < 0;
  };

  const isMature = (stock: StockData): boolean => {
    return stock.regression_score > 0;
  };

  const hasAlpha = (stock: StockData): boolean => {
    return stock.spy_correlation < 0.25;
  };

  const upProbabilityToday = (stock: StockData): boolean => {
    return stock.today_probability > 0.6;
  };

  const upProbabilityTomorrow = (stock: StockData): boolean => {
    return stock.tomorrow_probability > 0.6;
  };

  const isSignalRecentAndPositive = (stock: StockData): boolean => {
    return (
      stock.isRecent &&
      isSentimentPositive(stock) &&
      isCheap(stock) &&
      isTrendingUp(stock)
    );
  };

  const handleSymbolFiter = () => {
    if (symbol === "") {
      const activeOpportunities = scanData.filter((stock) => {
        const isRecentAndPositive = isSignalRecentAndPositive(stock);
        return isRecentAndPositive || stock.isSelected;
      });
      setFilteredScanData(activeOpportunities);
    } else {
      const filteredData = scanData.filter((item) => {
        return item.symbol.toLowerCase().includes(symbol.toLowerCase());
      });
      setFilteredScanData(filteredData);
    }
  };
  const handleActiveOpportunities = () => {
    if (filterValue === "active") {
      const activeOpportunities = scanData.filter((stock) => {
        const isRecentAndPositive = isSignalRecentAndPositive(stock);
        return isRecentAndPositive || stock.isSelected;
      });
      setFilteredScanData(activeOpportunities);
    } else if (filterValue === "positive") {
      const positiveOpportunities = scanData.filter((stock) => {
        return isSentimentPositive(stock);
      });
      setFilteredScanData(positiveOpportunities);
    } else if (filterValue === "up") {
      const trendingUpOpportunities = scanData.filter((stock) => {
        return isTrendingUp(stock);
      });
      setFilteredScanData(trendingUpOpportunities);
    } else if (filterValue === "cheap") {
      const cheapOpportunities = scanData.filter((stock) => {
        return isCheap(stock);
      });
      setFilteredScanData(cheapOpportunities);
    } else if (filterValue === "mature") {
      const matureOpportunities = scanData.filter((stock) => {
        return isMature(stock);
      });
      setFilteredScanData(matureOpportunities);
    } else if (filterValue === "alpha") {
      const alphaOpportunities = scanData.filter((stock) => {
        return hasAlpha(stock);
      });
      setFilteredScanData(alphaOpportunities);
    } else if (filterValue === "today") {
      const alphaOpportunities = scanData.filter((stock) => {
        return upProbabilityToday(stock);
      });
      setFilteredScanData(alphaOpportunities);
    } else if (filterValue === "tomorrow") {
      const alphaOpportunities = scanData.filter((stock) => {
        return upProbabilityTomorrow(stock);
      });
      setFilteredScanData(alphaOpportunities);
    } else {
      setFilteredScanData(scanData);
    }
  };
  useEffect(() => {
    handleActiveOpportunities();
  }, [filterValue, scanData]);

  useEffect(() => {
    handleSymbolFiter();
  }, [symbol]);

  useEffect(() => {
    checkLoginState();
    handleActiveOpportunities();
  }, []);

  const loginCallBack = () => {
    checkLoginState();
  };

  const handleLogout = () => {
    Api.token = "";
    localStorage.removeItem("accessToken");
    setIsLoggedIn(false);
  };

  return (
    <div className="App">
      <div className="logout-container">
        {isLoggedIn && (
          <button onClick={handleLogout} className="logout-button">
            Log Out
          </button>
        )}
      </div>
      <div className="main-logo">
        <img src="neural_wolf_logo_white.png"></img>
      </div>
      <p className="main-call">
        "Stocks chosen based on signals generated by proprietary predictive
        systems."
      </p>
      {isLoggedIn ? (
        <div>
          <div>
            <form onSubmit={handleSubmitSymbol} className="form-search">
              <input
                type="text"
                value={symbol}
                onChange={(event) => setSymbol(event.target.value)}
                placeholder="Enter symbol (e.g., AAPL)"
              />
              <button type="submit">SEARCH</button>
            </form>
          </div>
          <div>
            <div className="active-opportunities-select">
              <div>
                <select
                  value={filterValue}
                  onChange={(event) => handleActiveOpportunitiesFilter(event)}
                  style={{ width: "auto", height: "30px", fontSize: "16px" }}
                >
                  <option value="active">Recent Signals and Positions</option>
                  <option value="positive">Positive sentiment 🟢</option>
                  <option value="up">Trending up 📈</option>
                  <option value="cheap">Undervalued 💰</option>
                  <option value="mature">Mature 🔥</option>
                  <option value="alpha">Independent Movement α</option>
                  <option value="today">Today High Probability</option>
                  <option value="tomorrow">Tomorrow High Probability</option>
                  <option value="all">All Signals and Positions</option>
                </select>
              </div>
            </div>
            {isScanning ? (
              <div>
                <Spinner message="AI magic takes time, relax and go grab a cup of coffee." />
              </div>
            ) : (
              <div>
                <div className="marge-info-component">
                  {scanMaket ? (
                    <MarketInfo data={scanMaket} />
                  ) : (
                    <p></p>
                  )}
                </div>

                <div className="stock-data-component">
                  <StockDataDisplay
                    data={filteredScanData}
                    onItemClick={handleItemClick}
                    onCheckboxClick={handleCheckboxClick}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <Login loginCallback={loginCallBack} />
        </div>
      )}

      <div>
        <HistoricalModal
          isLoading={isLoading}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          data={data}
          news={scanData.find((item) => item.symbol === symbol)?.data}
        />
      </div>
    </div>
  );
}

export default App;
